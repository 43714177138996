import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-primary text-white pt-32 pb-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">
              Transform Your Healthcare Procurement with AI-Powered Insights
            </h1>
            <p className="text-xl mb-8 text-gray-200 max-w-lg">
              WellGroup's cutting-edge technology optimizes your healthcare supply chain, significantly reduces costs, and enhances patient care.
            </p>
            <a 
              href="#contact" 
              className="inline-block bg-white text-primary font-bold py-3 px-8 rounded-full hover:bg-gray-100 transition duration-300"
            >
              Optimize Your Procurement Today
            </a>
          </div>
          <div className="md:w-1/2">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-4 text-primary">Why Choose WellGroup?</h2>
              <ul className="space-y-4">
                {[
                  "Advanced AI-driven analytics dashboard",
                  "Powerful predictive forecasting",
                  "Tailored healthcare-specific insights",
                  "Flexible, customizable procurement solutions"
                ].map((benefit, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-6 h-6 mr-2 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span className="text-gray-700">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
