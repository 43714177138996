import React from 'react';

const HowItWorks = () => {
  const steps = [
    { 
      title: "Join", 
      description: "Become a member of our AI-powered healthcare GPO network, gaining access to our cutting-edge procurement platform."
    },
    { 
      title: "Integrate", 
      description: "Seamlessly connect your existing systems to our AI-driven analytics platform for comprehensive data analysis."
    },
    { 
      title: "Analyze", 
      description: "Leverage our AI to gain real-time insights into your procurement patterns, supply chain efficiency, and cost-saving opportunities."
    },
    { 
      title: "Optimize", 
      description: "Implement AI-recommended strategies to streamline operations, reduce costs, and enhance patient care through improved procurement."
    },
    {
      title: "Evolve",
      description: "Continuously improve your procurement processes with our AI's adaptive learning and predictive analytics."
    }
  ];

  return (
    <section id="how-it-works" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary">How WellGroup Works</h2>
        <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center text-xl font-bold mb-4 mx-auto">
                {index + 1}
              </div>
              <h3 className="text-xl font-semibold mb-2 text-primary">{step.title}</h3>
              <p className="text-sm text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
