import React from 'react';

const Header = () => {
  return (
    <header className="fixed w-full z-50 bg-white shadow-sm">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <a href="/" className="flex items-center">
            <h1 className="text-2xl font-bold text-primary" style={{ fontFamily: 'Playfair Display, serif' }}>WellGroup</h1>
          </a>
          <nav className="hidden md:flex space-x-8">
            {['Benefits', 'AI Solutions', 'How It Works', 'Testimonials'].map((item) => (
              <a 
                key={item}
                href={`#${item.toLowerCase().replace(' ', '-')}`} 
                className="text-sm font-medium text-text hover:text-primary transition-colors duration-300"
              >
                {item}
              </a>
            ))}
          </nav>
          <a 
            href="#contact" 
            className="px-4 py-2 text-sm font-medium rounded-full bg-primary text-white hover:bg-secondary transition-all duration-300"
          >
            Get Started
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
