import React from 'react';
import Layout from './components/Layout';
import HeroSection from './components/HeroSection';
import OverviewSection from './components/OverviewSection';
import HowItWorks from './components/HowItWorks';
import Testimonials from './components/Testimonials';
import CTASection from './components/CTASection';

function App() {
  return (
    <Layout>
      <HeroSection />
      <OverviewSection />
      <HowItWorks />
      <Testimonials />
      <CTASection />
    </Layout>
  );
}

export default App;
