import React from 'react';

const BenefitCard = ({ title, description, icon }) => (
  <div className="flex items-start space-x-4">
    <div className="text-primary flex-shrink-0">{icon}</div>
    <div>
      <h4 className="text-lg font-semibold mb-2 text-primary">{title}</h4>
      <p className="text-gray-600 text-sm">{description}</p>
    </div>
  </div>
);

const OverviewSection = () => {
  const benefits = [
    {
      title: "AI-Driven Cost Optimization",
      description: "Our advanced AI algorithms analyze your procurement data to identify cost-saving opportunities, negotiate better contracts, and optimize spending across your healthcare supply chain.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    },
    {
      title: "Intelligent Procurement Automation",
      description: "Streamline your healthcare purchasing process with our AI-powered platform, automating routine tasks, predicting supply needs, and ensuring compliance with healthcare regulations.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>
    },
    {
      title: "Predictive Supply Chain Management",
      description: "Leverage our AI models to forecast demand, optimize inventory levels, and mitigate risks in your healthcare supply chain, ensuring critical supplies are always available when needed.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /></svg>
    },
    {
      title: "Healthcare-Specific Analytics",
      description: "Access deep, actionable insights tailored to healthcare procurement, including drug utilization trends, medical equipment lifecycle analysis, and supply usage patterns across different departments.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
    }
  ];

  return (
    <section id="overview" className="py-24 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-center text-primary">About WellGroup</h2>
          <p className="text-xl text-center mb-16 text-gray-700 leading-relaxed">
            WellGroup is at the forefront of healthcare procurement innovation, harnessing the power of artificial intelligence and real-time data analytics. Our cutting-edge platform is designed specifically for the healthcare industry, offering unparalleled insights, predictive forecasting, and customizable solutions that optimize your supply chain, reduce costs, and ultimately improve patient care.
          </p>
          
          <div>
            <h3 className="text-2xl font-semibold mb-10 text-center text-primary">AI-Powered Benefits for Healthcare Procurement</h3>
            <div className="grid md:grid-cols-2 gap-10">
              {benefits.map((benefit, index) => (
                <BenefitCard key={index} {...benefit} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverviewSection;
