import React from 'react';

const CTASection = () => {
  return (
    <section id="contact" className="py-24 bg-primary">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="p-8 md:p-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-primary text-center">Ready to Transform Your Healthcare Procurement?</h2>
            <p className="text-xl mb-8 text-gray-600 text-center">
              Join WellGroup today and harness the power of AI to optimize your procurement process. Experience:
            </p>
            <ul className="list-disc list-inside mb-8 text-gray-600">
              <li>Up to 30% reduction in procurement costs</li>
              <li>75% decrease in stockouts</li>
              <li>Real-time insights and predictive analytics</li>
              <li>Customized AI-driven procurement strategies</li>
              <li>Improved patient care through optimized supply management</li>
            </ul>
            <div className="flex justify-center">
              <a 
                href="/schedule-demo" 
                className="bg-primary text-white font-bold py-3 px-8 rounded-full hover:bg-secondary transition duration-300 text-lg inline-block"
              >
                Schedule a Free AI Procurement Analysis
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
