import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-background text-text py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <h2 className="text-3xl font-bold text-primary" style={{ fontFamily: 'Playfair Display, serif' }}>WellGroup</h2>
            <p className="text-sm text-gray-600">Transforming healthcare procurement with AI-driven insights</p>
          </div>
          <nav className="flex flex-wrap justify-center md:justify-end gap-6">
            <a href="#benefits" className="text-sm text-gray-600 hover:text-primary transition-colors">Benefits</a>
            <a href="#ai-solutions" className="text-sm text-gray-600 hover:text-primary transition-colors">AI Solutions</a>
            <a href="#how-it-works" className="text-sm text-gray-600 hover:text-primary transition-colors">How It Works</a>
            <a href="#testimonials" className="text-sm text-gray-600 hover:text-primary transition-colors">Testimonials</a>
            <a href="#contact" className="text-sm text-gray-600 hover:text-primary transition-colors">Contact</a>
          </nav>
        </div>
        <div className="mt-8 text-center text-sm text-gray-600">
          <p>&copy; 2024 WellGroup. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
