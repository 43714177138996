import React from 'react';

const TestimonialCard = ({ quote, author, company }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="text-gray-600 italic mb-4">"{quote}"</p>
    <p className="font-semibold text-primary">{author}</p>
    <p className="text-sm text-gray-500">{company}</p>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    {
      quote: "WellGroup's AI-driven analytics have not only reduced our procurement costs by 30% but also improved our supply chain reliability. The predictive insights have been invaluable in managing inventory during peak seasons.",
      author: "Dr. Emily Chen",
      company: "Metropolitan Health System"
    },
    {
      quote: "The AI-powered forecasting tool has revolutionized our inventory management. We've reduced stockouts by 75% and optimized our storage space, directly impacting patient care quality.",
      author: "Michael Rodriguez",
      company: "Central State Hospital"
    },
    {
      quote: "As a rural clinic, WellGrouop's AI platform has given us access to procurement strategies we never thought possible. We're now able to negotiate better contracts and have reduced our supply costs by 25%.",
      author: "Dr. Sarah Thompson",
      company: "Heartland Community Health Center"
    }
  ];

  return (
    <section id="testimonials" className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary">What Our Members Say</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
